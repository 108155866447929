import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [];

export const dictionary = {
		"/(firebase)/(not-authed)": [26,[2,8]],
		"/(firebase)/(authed)/add-portfolio": [14,[2,3]],
		"/(firebase)/(authed)/add-portfolio/(add-portfolio-selection)/automatic": [15,[2,3,5]],
		"/(firebase)/(authed)/add-portfolio/(add-portfolio-selection)/manual": [16,[2,3,5]],
		"/(firebase)/(authed)/assets/[equityId]": [17,[2,3,6]],
		"/(firebase)/(authed)/assets/[equityId]/(addOrEdit)/add": [18,[2,3,6,7]],
		"/(firebase)/(authed)/assets/[equityId]/(addOrEdit)/edit": [19,[2,3,6,7]],
		"/(firebase)/auth/action": [35,[2]],
		"/(firebase)/(authed)/billing": [20,[2,3]],
		"/(firebase)/(authed)/(activePortfolio)/calendar": [11,[2,3,4]],
		"/(firebase)/(not-authed)/dividend-income-calculator": [27,[2,8]],
		"/(firebase)/(not-authed)/ex-dividend-calendar": [28,[2,8]],
		"/(firebase)/(not-authed)/forgot-password": [29,[2,8]],
		"/(firebase)/(authed)/help": [21,[2,3]],
		"/(firebase)/(not-authed)/login": [30,[2,8]],
		"/(firebase)/(authed)/my-account": [22,[2,3]],
		"/(firebase)/(authed)/my-portfolios": [23,[2,3]],
		"/(firebase)/(authed)/(activePortfolio)/overview": [12,[2,3,4]],
		"/(firebase)/(others)/plaid/redirect": [33,[2,9]],
		"/(firebase)/(authed)/(activePortfolio)/portfolio": [13,[2,3,4]],
		"/(firebase)/(not-authed)/pricing": [31,[2,8]],
		"/privacy-policy": [36],
		"/refund-policy": [37],
		"/(firebase)/(not-authed)/signup": [32,[2,8]],
		"/(firebase)/(others)/snap-trade/redirect": [34,[2,9,10]],
		"/terms": [38],
		"/(firebase)/(authed)/tools/dividend-income-calculator": [24,[2,3]],
		"/(firebase)/(authed)/tools/ex-dividend-calendar": [25,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';